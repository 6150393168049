/* eslint no-console: "off" */
/**
 *
 * Logger
 * Utility class for logging
 *
 */

import { Logger as ILogger } from '@kittl/logger';

const RESET = '\x1b[0m';
const CYAN = '\x1b[36m';
const RED = '\x1b[31m';
const YELLOW = '\x1b[43m';

class Logger implements ILogger {
  getValue(rawData: Record<string, any>): string {
    if (rawData === undefined) {
      return '';
    }
    return JSON.stringify(rawData);
  }

  debug(message: string, details?: Record<string, any>): void {
    if (process.env.NODE_ENV === 'development' || process.env.DEBUG) {
      console.debug(`[DEBUG]`, message, this.getValue(details));
    }
  }
  trace(message: string, details?: Record<string, any>): void {
    if (process.env.NODE_ENV === 'development' || process.env.DEBUG) {
      console.trace(`[TRACE]`, message, this.getValue(details));
    }
  }
  info(message: string, details?: Record<string, any>): void {
    if (process.env.NODE_ENV !== 'test') {
      console.info(`${CYAN}[INFO]${RESET}`, message, this.getValue(details));
    }
  }
  warn(message: string, details?: Record<string, any>): void {
    if (process.env.NODE_ENV !== 'test') {
      console.warn(`${YELLOW}[WARN]${RESET}`, message, this.getValue(details));
    }
  }
  error(message: string, error?: any, details?: Record<string, any>): void {
    console.error(
      `${RED}[ERROR]${RESET}`,
      message,
      error,
      this.getValue(details)
    );
  }
  fatal(message: string, error?: any, details?: Record<string, any>): void {
    console.error(
      `${RED}[FATAL]${RESET}`,
      message,
      error,
      this.getValue(details)
    );
  }
  child(_: string, _obj?: Record<string, any>): ILogger {
    return this;
  }
}

export default new Logger();
